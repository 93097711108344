import React from 'react'
import '../css/AboutUs.css'

function AboutUs() {
  return (
    <>

<div className='container-fluid'>
            <div className='row'>
            <a id="About"></a>
                <h1 className='Services-header'>About Us</h1>
                <br/>
                <div className='col-md-6 text'>
                    <h2 className='text'>We are an especialized team with years of experiece in installation and customization of residential and commercial doors.</h2>
                </div>

                <div className='col-md-6 slider'>
                    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                            <img src="https://i.ibb.co/JvYYfHr/1.webp" className="d-block w-100" alt="..."/>
                            </div>
                            <div className="carousel-item">
                            <img src='https://i.ibb.co/Bg0DJqB/2.webp' className="d-block w-100" alt="..."/>
                            </div>
                            <div className="carousel-item">
                            <img src='https://i.ibb.co/F03tk6R/3.webp' className="d-block w-100" alt="..."/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>

                <div className='col-md-6 About-text'>
                <br/>
                <i class="bi bi-bookmark-check icon-residential"></i>
                    <h1>Mission</h1>
                    <p className='about-paragraph'>Our mission is to provide high-quality garage doors, installation, and maintenance services to our residential and commercial customers. We are committed to delivering exceptional customer service and ensuring the safety and reliability of our products and services.  </p>
                </div>

                <div className='col-md-6 About-text'>
                <br/>
                <i className="bi bi-graph-up-arrow icon-residential"></i>
                    <h1>Vision</h1>
                    <p className='about-paragraph'>Our vision is to become the leading garage door company in our region by providing innovative and superior quality products and services that meet our customers' needs. We aim to exceed our customers' expectations and establish long-term relationships with them based on trust, reliability, and excellence.  </p>
                </div>
            
            </div>
        </div>

    </>
  )
}

export default AboutUs