import React from 'react';
import '../css/Services.css';
import img1 from '../img/residential-spring.jpg'
import img2 from '../img/residential-tune.jpeg'
import img3 from '../img/residential-installation.jpeg'
import img4 from '../img/residential-opener.jpg'
import img5 from '../img/commercial-installation.jpg'
import img6 from '../img/commercial-repair.jpg'
import img7 from '../img/commercial-tune.jpeg'
import img8 from '../img/commercial-motors.jpg'

function Services() {
  return (
    <>
        <div className='container-fluid'>
            <div className='row'>
        <a id="Services"></a>
                <h1 className='Services-header'>Services</h1>
                <br/>
            <div className='col-md-6 residential Services-text'>
            <i className="bi bi-house-door icon-residential"></i>
                    <h1>Residential</h1>
                    <p className='residential-text'>Our doors are made of high-quality materials to ensure long-lasting durability and safety for your family.  </p>

                    <div className="card" >
                      <img src={img1} className="card-img-top" alt="..." />
                        <div className="card-body">
                          <h5 className="card-title">Springs replacement</h5>
                          <p className="card-text text-service">Our experienced technicians can assess the condition of your springs and recommend the best replacement option for your specific needs. We only use high-quality springs that are designed to last longer, minimize maintenance, and reduce the need for frequent replacements.</p>
                          
                        </div>
                      </div>

                      <div className="card" >
                      <img src={img2} className="card-img-top" alt="..." />
                        <div className="card-body">
                          <h5 className="card-title">Tune Up</h5>
                          <p className="card-text text-service">Regular garage door tune-ups can extend the life of your door, prevent wear and tear on the components, and minimize the risk of safety hazards. In addition to the tune-up, our technicians can provide guidance on how to properly maintain your garage door and offer suggestions on upgrades or replacements if needed.</p>
                          
                        </div>
                      </div>

                      <div className="card" >
                      <img src={img3} className="card-img-top" alt="..." />
                        <div className="card-body">
                          <h5 className="card-title">Garage Door Installation</h5>
                          <p className="card-text text-service">Our experienced team of technicians can assess your garage's specifications and recommend the best door for your home. We only work with trusted manufacturers to ensure that our products are of the highest quality, durable, and reliable.</p>
                          
                        </div>
                      </div>

                      <div className="card" >
                      <img src={img4} className="card-img-top" alt="..." />
                        <div className="card-body">
                          <h5 className="card-title">Garage Door Opener</h5>
                          <p className="card-text text-service">Garage door openers provide convenience, safety, and security for your home, and choosing the right one is essential. At our garage door opener website, we offer a wide range of high-quality openers to match your garage door and specific needs.</p>
                          
                        </div>
                      </div>
                    
                  </div>

            <div className='col-md-6 commercial Services-text'>
            <i className="bi bi-buildings icon-commercial"></i>
                <h1>Commercial</h1>
                
                <p className='commercial-text'>We offer professional installation services to ensure that your commercial door is installed safely and efficiently. </p>

                <div className="card" >
                      <img src={img5} className="card-img-top" alt="..." />
                        <div className="card-body">
                          <h5 className="card-title">Installation</h5>
                          <p className="card-text text-service">We also offer professional installation services to ensure that your commercial door is installed safely and efficiently. Our technicians have the experience and expertise necessary to install any door type or size, minimizing downtime and disruptions to your business.</p>
                          
                        </div>
                      </div>

                      <div className="card" >
                      <img src={img6} className="card-img-top" alt="..." />
                        <div className="card-body">
                          <h5 className="card-title">Commercial Door Repair</h5>
                          <p className="card-text text-service">Our experienced technicians can assess the condition of your commercial door and recommend the best repair option for your specific needs. We have the expertise to repair any door type or size, from roll-up doors to overhead doors and dock levelers.</p>
                          
                        </div>
                      </div>

                      <div className="card" >
                      <img src={img7} className="card-img-top" alt="..." />
                        <div className="card-body">
                          <h5 className="card-title">Tune Ups</h5>
                          <p className="card-text text-service">Regular tune-ups for your commercial doors are essential to ensure they are operating safely and efficiently. At our tune-ups commercial doors website, we offer comprehensive tune-up services to keep your commercial doors in excellent working condition and prevent costly breakdowns.</p>
                          
                        </div>
                      </div>

                      <div className="card" >
                      <img src={img8} className="card-img-top" alt="..." />
                        <div className="card-body">
                          <h5 className="card-title">Motor Installation</h5>
                          <p className="card-text text-service">Our motors are manufactured by trusted brands and come in various types and sizes to meet your specific needs. We can install chain, belt, or direct-drive motors, depending on your door type, size, while improving your business's productivity.</p>
                          
                        </div>
                      </div>
                
            </div>

    
            </div>
        </div>
    </>
  )
}

export default Services
