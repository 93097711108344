import NavBar from './NavBar';
import Banner from './Banner';
import Services from './Services';
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';
import '../css/App.css';


function App() {
  return (
    <div className="App">
      <NavBar/>
      <Banner/>
      <Services/>
      <AboutUs/>
      <ContactUs/>
    </div>
  );
}

export default App;
