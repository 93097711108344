import React from 'react'
import '../css/Banner.css'

function Banner() {
  return (
    <>
        <div className='container-fluid img-banner'>
        <div className='row'>

        <div className='col-md-12 jumbox' >
          
          <div className='text-banner'>
          <h2>With a wide range of styles, materials, and prices, it's easy to find a door that meets your specific needs and budget.</h2>
        <br/>
              
              <p className="lead">
                  <a className="btn btn-primary btn-lg contact-us-btn" href="#Contact" role="button">Contact us</a>
              </p>
          </div>
              
         
        </div>

        </div>
            

            
        </div>

    </>
  )
}

export default Banner