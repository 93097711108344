import React from 'react'
import '../css/ContactUs.css'

function ContactUs() {
  return (
    <>
    <div className='container '>
        <div className="row ">
        <a id="Contact"></a>
        <h1 className='Services-header'>Contact Us</h1>

        <div className="col-md-12 form">
        <form action="" method="" className="row g-3">
            <div className="col-md-4 name">
            <label htmlFor="validationDefault01" className="form-label ">Name</label>
            <input type="text" className="form-control" id="validationDefault01" name="nombre" required="" /> 
            </div>
            <div className="col-md-4 lastname">
            <label htmlFor="validationDefault02" className="form-label ">Lastname</label>
            <input type="text" className="form-control" id="validationDefault02" name="apellidos" required="" />
            </div>
            <div className="col-md-4 phone">
            <label htmlFor="validationDefaultUsername" className="form-label ">Telephone</label>
            <div className="input-group">
                <span className="input-group-text" id="inputGroupPrepend2">#</span>
                <input type="text" className="form-control" id="validationDefaultUsername" name="telefono" aria-describedby="inputGroupPrepend2" required="" />
            </div>
            </div>
            <div className="col-md-12 ask">
            <label htmlFor="validationDefault03" className="form-label ">What do you need?</label>
            <textarea name="mensaje" id="validationDefault03" className="form-control"></textarea>
            </div>
            
            <div className="col-12 button">
            <input className="btn btn-primary contact-us-btn-form" type="submit" disabled value="Send a Question"/>
            </div>
        </form>
        </div>
                <div className="col-md-12">
                <br/>
               
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3257.295598927233!2d-80.7249919839148!3d35.27377615981579!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88541ecabfd5b59b%3A0x543a9e52658ff85e!2s2541%20Lassen%20Bay%20Pl%2C%20Charlotte%2C%20NC%2028215%2C%20EE.%20UU.!5e0!3m2!1ses!2scr!4v1676845619223!5m2!1ses!2scr" width="100%" height="450" frameBorder="0" style={{border:0}} allowFullScreen="" title="myMap" aria-hidden="false" tabIndex="0"/>

                </div>
        </div>
    </div>
    </>
  )
}

export default ContactUs