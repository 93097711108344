import React from 'react'
import '../css/NavBar.css'


function NavBar() {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark" aria-label="Tenth navbar example">
    <div className="container-fluid">
      <button className="navbar-toggler menu-icon" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample08" aria-controls="navbarsExample08" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse justify-content-center" id="navbarsExample08">
          <a className="nav-item7" href='#'>
            <img className=" nav-img" src="https://i.ibb.co/YhNxZfh/Logo.png" alt="Logo"/>
          </a>
        <ul className="navbar-nav">
        
          <li className="nav-item">
            <a className="nav-link active home" aria-current="page" href="#">Home</a>
          </li>
          <li className="nav-item">
            <a className="nav-link active" aria-current="page" href="#Services">Services</a>
            
          </li>
          <li className="nav-item">
            <a className="nav-link active" aria-current="page" href="#About">About Us</a>
            
          </li>
          <li className="nav-item">
            <a className="nav-link active contact" aria-current="page" href="#Contact">Contact Us</a>
            <br/>
          </li>
        </ul>
      </div>
    </div>
  </nav>
    </>
  )
}

export default NavBar